import Footer from "./footer";
import Navigation from "./navigation";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Navigation />
      <main className="w-full overflow-hidden">{children}</main>
      <Footer />
    </>
  );
}
