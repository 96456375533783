import Section from "@/components/Section";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import SimpleButton from "@/components/SimpleButton";
import { TicketGrey } from "@/components/Ticket";
import { useState } from "react";
import {
  useAccount,
  useBalance,
  useContractRead,
  useContractReads,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";
import ABIs, {
  DAO_ABI,
  LUCKY8_ABI,
  OLD_ABI,
  TICKETS_ABI,
} from "@/constants/abis";
import addresses, {
  DAO,
  LUCKY8,
  OLD_DAO,
  OLD_LUCKY8,
  OLD_LUCKY8_V1,
  TICKETS,
} from "@/constants/addresses";
// import NextDraw from "@/components/NextDraw";
import { formatUnits, zeroAddress } from "viem";
import { Logomark, USDCLogo } from "@/components/Logos";
import { formatNum } from "@/lib/utils";
import useDapp from "@/contexts/useDapp";
import InputAction from "@/components/InputAction";

export default function Migrate() {
  const { data, epoch, balances } = useDapp();
  const { address } = useAccount();
  const { drawExecuted, winnersAt, winningTickets } = epoch;

  const { data: token } = useBalance({
    address: address || zeroAddress,
    token: OLD_LUCKY8,
    watch: true,
  });
  const { data: tokenv1 } = useBalance({
    address: address || zeroAddress,
    token: OLD_LUCKY8_V1,
    watch: true,
  });

  const { data: tokens } = useContractRead({
    abi: OLD_ABI,
    address: OLD_DAO,
    functionName: "getUserInfo",
    args: [address || zeroAddress],
    watch: true,
  }) as { data: bigint[] };

  const lockedTokens = tokens ? tokens[0] : BigInt(0);

  // Write REGISTER CODE
  const { config: configClaim, error: errorClaim } = usePrepareContractWrite({
    address: DAO,
    abi: DAO_ABI,
    functionName: "claimPrize",
    args: [data.epoch as bigint],
  });
  const { write: writeClaim } = useContractWrite(configClaim);

  return (
    <Layout>
      <Section className="max-w-5xl" grad>
        <div className="z-10 bg-foreground w-fit mx-auto py-10 px-12 text-center rounded-xl max-w-md">
          <div className="relative w-24 h-20 mb-8 mx-auto">
            <div className="mx-auto mb-2 relative h-20 w-20 bg-primary p-6 rounded-[40px]">
              <Logomark
                className="h-12 absolute translate-y-1/2 -translate-x-1/2 bottom-1/2 left-1/2 "
                fill="#AB2417"
              />
            </div>
          </div>
          <div className="text-4xl font-bold mb-4">Token Migration</div>
          <p className="text-sm mb-4 ">
            {`Initiate your token migration below. 
            If you still have $888 staked in the old lottery contract, 
            make sure to unstake them first.`}
          </p>
          <p className="text-sm my-3 text-gray-400">{`Unstake old $888 from lottery contract:`}</p>
          <InputAction
            balance={lockedTokens}
            address={OLD_DAO}
            abi={OLD_ABI}
            name="Unstake Tokens"
            functionName="unlockTokens"
          />

          <p className="text-sm my-3 text-gray-400">{`Migrate $888 V1 to the new V3 $888 token:`}</p>
          <InputAction
            balance={token?.value || BigInt(0)}
            address={LUCKY8}
            approvee={OLD_LUCKY8}
            abi={LUCKY8_ABI}
            name="Migrate Tokens"
            functionName="migrateFromV1"
          />

          <p className="text-sm my-3 text-gray-400">{`Migrate $888 V2 to the new V3 $888 token:`}</p>
          <InputAction
            balance={tokenv1?.value || BigInt(0)}
            address={LUCKY8}
            approvee={OLD_LUCKY8_V1}
            abi={LUCKY8_ABI}
            name="Migrate V2 Tokens"
            functionName="migrateFromV2"
          />
          {balances.wallet > 0 && (
            <p className="text-xs pt-4 text-white/50">
              {`Congratulations! You've successfully migrated your tokens.`}
              <br />
              {`You can always come back to migrate more.`}
            </p>
          )}
        </div>
      </Section>
    </Layout>
  );
}
