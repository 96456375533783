// we start in epoch 0 and CURRENT_EPOCH_START allows to advance to epoch 1, starting the first draw
export const CURRENT_EPOCH_OFFSET = 1;
// the timestamp when the first draw should happen
// eg Fri Dec 22 2023 15:00:00 GMT+0000
export const CURRENT_EPOCH_START = 1703257200;
export const CURRENT_EPOCH_PERIOD = 86400; // a draw every 24 hours

/* Governance */
export const GOVERNANCE_PERIOD = 3; // 3 epochs
export const GOVERNANCE_EXPIRATION = 2; // 2 + 1 epochs
export const GOVERNANCE_QUORUM = 25e16; // 25%
export const GOVERNANCE_PROPOSAL_THRESHOLD = 15e15; // 1,5%
export const GOVERNANCE_SUPER_MAJORITY = 40e16; // 40%
export const GOVERNANCE_EMERGENCY_DELAY = 3; // 3 epochs

/* DAO */
export const DAO_EXIT_LOCKUP_EPOCHS = 2; // 2 epochs fluid

/* Lottery */
export const DISTRICUTION_PER_EPOCH = 10e16; // 10% distribution per epoch
export const WINNINGTICKETS = 3; // 3 winners per epoch
