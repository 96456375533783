import { formatNum } from "@/lib/utils";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Abi, formatUnits, parseUnits, zeroAddress } from "viem";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import SimpleButton from "../SimpleButton";
import ConnectGuard from "../guards/ConnectGuard";
import ApproveGuard from "../guards/ApproveGuard";

export default function InputAction({
  balance,
  address,
  abi,
  functionName,
  name,
  approvee,
  price,
  inline,
}: {
  balance: BigInt;
  address: `0x${string}`;
  abi: Abi;
  functionName: string;
  name?: string;
  approvee?: `0x${string}`;
  price?: number;
  inline?: boolean;
}) {
  const [amount, setAmount] = useState<null | { str: string; bigInt: BigInt }>({
    str: "0",
    bigInt: BigInt(0),
  });
  const handleBigInt = (string: string, decimals: number) => {
    const str: string = string.replace(/[a-zA-Z]/g, "");
    if (str === "") return setAmount({ str: "", bigInt: BigInt(0) });
    try {
      let bigInt: BigInt = parseUnits(str, decimals);
      setAmount({ str, bigInt });
    } catch (error) {}
  };

  const handleMax = () => {
    setAmount({
      str: formatUnits(balance as bigint, 18),
      bigInt: balance,
    });
  };

  const { data: allowance } = useContractRead({
    address: approvee || zeroAddress,
    abi: abi,
    functionName: "allowance",
    args: [address, approvee],
    watch: true,
  });

  const { config } = usePrepareContractWrite({
    address,
    abi,
    functionName,
    args: [amount?.bigInt],
  });
  const { data, write } = useContractWrite(config);

  const { isSuccess, isError, isLoading } = useWaitForTransaction({
    hash: data?.hash,
  });

  const sendTx = () => {
    write && write();
  };

  useEffect(() => {
    if (data && isLoading) {
      toast.success("Transaction Sent!");
      setAmount({ str: "", bigInt: BigInt(0) });
    }
    if (isSuccess && data) {
      toast.success("Transaction Confirmed!");
    }
  }, [data]);

  return (
    <div
      className={`w-full flex mt-6 ${
        inline ? "flex-row items-center justify-center" : "flex-col"
      }`}
    >
      <div
        className={`${
          inline ? "mr-2" : "mb-3"
        } relative flex rounded-xl outline outline-1 outline-white/30 py-1 px-1 justify-between items-center`}
      >
        <div className="w-full text-right text-xs text-white/50 mt-3 absolute -top-8 right-1">
          {`Balance: ${formatNum(Number(formatUnits(balance as bigint, 18)))}`}
        </div>
        <div className="flex flex-col items-start pl-2">
          <input
            className="bg-transparent block text-white text-lg py-1 focus-within:outline-none focus-within:none focus-within:nones"
            placeholder="0"
            type="string"
            value={amount ? amount.str : undefined}
            onChange={(e) => handleBigInt(e.target.value, 18)}
          />
          {price && (
            <span className="text-xs text-white/50">{`≈ ${Math.floor(
              Number(formatUnits(amount?.bigInt as bigint, 18)) * price || 0
            )} tickets`}</span>
          )}
        </div>
        <SimpleButton
          color="bg-primary"
          className="text-black py-3 h-fit"
          onClick={() => handleMax()}
        >
          MAX
        </SimpleButton>
      </div>
      <ConnectGuard>
        {approvee ? (
          <ApproveGuard
            spender={address}
            token={approvee || zeroAddress}
            amount={balance}
          >
            <SimpleButton
              disabled={!write}
              color={!write ? "bg-[#23272F]" : "bg-primary"}
              className={`${
                !write ? "text-white/50" : "text-black"
              } py-3 w-full text-black capitalize `}
              onClick={() => write && sendTx()}
            >
              {name || functionName}
            </SimpleButton>
          </ApproveGuard>
        ) : (
          <SimpleButton
            disabled={!write}
            color={!write ? "bg-[#23272F]" : "bg-primary"}
            className={`${
              !write ? "text-white/50" : "text-black"
            } py-3 w-full text-black capitalize`}
            onClick={() => write && sendTx()}
          >
            {name || functionName}
          </SimpleButton>
        )}
      </ConnectGuard>
    </div>
  );
}
