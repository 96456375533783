export const USDC: `0x${string}` = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
export const OLD_DAO: `0x${string}` =
  "0xCaC93d18f237e355B71eC00293Ae93aE186257Ea";
export const OLD_LUCKY8: `0x${string}` =
  "0x8880111018C364912dBe5Ee61D98942647680888";

export const OLD_LUCKY8_V1: `0x${string}` = `0x35722BC146938c8B0d39f3e192da3DCCfD8a2e57`;

// Localhost
// export const DAO: `0x${string}` = "0x8d375dE3D5DDde8d8caAaD6a4c31bD291756180b";
// export const LUCKY8: `0x${string}` =
//   "0x19128339813F3cAc5D55a0bf29b60FA40599418D";
// export const TICKETS: `0x${string}` =
//   "0xBA90c4D3F1299E9fe744A9394CAB1E44DA001F0b";

// // NEW
export const DAO: `0x${string}` = "0x3ba65ad297a3b0b3c00508ebf5bc3d72c9d5f1a5";
export const LUCKY8: `0x${string}` =
  "0x5e72AD4Bf50c952B11A63B6769d02bB486A9a897";
export const TICKETS: `0x${string}` =
  "0x7a050Ec63f79523CF688c3Eeb8fDB2CDF429C54f";

// PRICING
export const LP: `0x${string}` = "0x9695df464bb9b86198fcb87442ddce2a2d327c3f";

const addresses = { USDC, DAO, LUCKY8, TICKETS, OLD_LUCKY8, LP, OLD_DAO };
export default addresses;
