import CallToAction from "@/components/CallToAction";
import FAQs from "../components/Faqs";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import ProjectOverview from "../components/ProjectOverview";

export default function Home() {
  return (
    <Layout>
      <Hero />
      <ProjectOverview />
      <FAQs />
      <CallToAction />
    </Layout>
  );
}
