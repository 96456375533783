import Section from "../Section";

export default function ProjectOverview() {
  return (
    <Section>
      <h2 className="text-3xl mb-16">Overview</h2>
      <Step
        number={"01"}
        title={"Buy $888"}
        description={
          "The first step to participate in the lottery is to buy $888. $888 is Lucky8’s native token that incorporates a 10% buy/sell tax going to the lottery prize pot. The prize pot will be distributed to the lottery winners."
        }
        src={"/product-1.png"}
      />
      <Step
        number={"02"}
        title={"Bond $888"}
        description={
          "The final step to participate in the lottery is to own lottery tickets. Lottery tickets can be obtained by bonding $888 tokens. Each $888 bonded counts as one lottery ticket. And for each lottery draw, new tickets have to be minted."
        }
        src={"/product-2.png"}
      />
      <Step
        number={"03"}
        title={"Win the lottery!"}
        description={
          "A fully on-chain randomness shuffle will determine the three lottery winners."
        }
        src={"/product-3.png"}
      />
    </Section>
  );
}

const Step = ({ number, title, description, src }: any) => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-2 mb-24">
      <div>
        <h3 className="text-4xl mb-4 text-primary">{number}</h3>
        <h4 className="text-2xl mb-4"> {title}</h4>
        <p className="text-sm w-2/3">{description}</p>
      </div>
      <div className="w-full">
        <img src={src} alt="" />
      </div>
    </div>
  );
};
