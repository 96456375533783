import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useAccount, useContractReads, usePublicClient } from "wagmi";

import { DAO_ABI, TICKETS_ABI, LUCKY8_ABI, LP_TOKEN } from "../constants/abis";
import { DAO, TICKETS, LUCKY8, LP } from "../constants/addresses";
import { zeroAddress } from "viem";

type Props = {
  children?: React.ReactNode;
};

// Contracts
const daoContract = {
  address: DAO,
  abi: DAO_ABI,
};
const ticketsContract = {
  address: TICKETS,
  abi: TICKETS_ABI,
};
const lucky8Contract = {
  address: LUCKY8,
  abi: LUCKY8_ABI,
};

// Calls
const staticCalls = [
  "epoch",
  "epochTime",
  "thisEpochsPrizePool",
  "thisEpochsTokenRewards",
  "totalBonded",
  "totalPrizePool",
  "totalStaged",
  "totalSupply",
  "totalTokenRewards",
  "totalUserTokenClaims",
  "userUSDCClaims",
];

const userCalls = ["balanceOfStaged", "balanceOfBonded", "fluidUntil"];

const epochCalls = [
  "winnersAt",
  "winningTickets",
  "prizePerTicket",
  "drawExecuted",
];

const tokenCalls = ["totalSupply"];

type DappContract = {
  epoch: bigint;
  epochTime: bigint;
  thisEpochsPrizePool: bigint;
  thisEpochsTokenRewards: bigint;
  totalBonded: bigint;
  totalPrizePool: bigint;
  totalStaged: bigint;
  totalSupply: bigint;
  totalTokenRewards: bigint;
  totalUserTokenClaims: bigint;
  userUSDCClaims: bigint;
};

const nullData: DappContract = {
  epoch: BigInt(0),
  epochTime: BigInt(0),
  thisEpochsPrizePool: BigInt(0),
  thisEpochsTokenRewards: BigInt(0),
  totalBonded: BigInt(0),
  totalPrizePool: BigInt(0),
  totalStaged: BigInt(0),
  totalSupply: BigInt(0),
  totalTokenRewards: BigInt(0),
  totalUserTokenClaims: BigInt(0),
  userUSDCClaims: BigInt(0),
};

const nullBalances = {
  wallet: BigInt(0),
  staged: BigInt(0),
  bonded: BigInt(0),
  fluid: BigInt(0),
};

const nullEpoch = {
  winnersAt: BigInt(0),
  winningTickets: [BigInt(0)],
  prizePerTicket: BigInt(0),
  drawExecuted: false,
  totalSupply: BigInt(0),
  balanceOf: BigInt(0),
};

// create context
export const UseDappContext = createContext({
  data: nullData,
  time: BigInt(0),
  epoch: nullEpoch,
  balances: nullBalances,
  price: 0.001,
  token: { totalSupply: BigInt(0) },
});

export const DappProvider: React.FC<Props> = ({ children }) => {
  const publicClient = usePublicClient();
  const { address: userAddress } = useAccount();

  /// Static Data
  const { data: readData } = useContractReads({
    contracts: staticCalls.map((key) => ({
      ...daoContract,
      functionName: key,
    })),
    watch: true,
  });
  const [data, setConfig] = useState(nullData);
  useEffect(() => {
    const configData: any = {};
    readData?.map(
      (res, i) =>
        (configData[staticCalls[i]] = res.result ? res.result : BigInt(0))
    );
    setConfig(configData);
  }, [readData, publicClient]);

  /// Balances
  const { data: readBalances } = useContractReads({
    contracts: [
      {
        ...lucky8Contract,
        functionName: "balanceOf",
        args: [userAddress ? userAddress : zeroAddress],
      },
      ...userCalls.map((key) => ({
        ...daoContract,
        functionName: key,
        args: [userAddress ? userAddress : zeroAddress],
      })),
    ],
    watch: true,
  });
  const [balances, setBalances] = useState<{
    wallet: bigint;
    staged: bigint;
    bonded: bigint;
    fluid: bigint;
  }>(nullBalances);
  useEffect(() => {
    setBalances({
      wallet: BigInt((readBalances?.[0]?.result as bigint) || 0),
      staged: BigInt((readBalances?.[1]?.result as bigint) || 0),
      bonded: BigInt((readBalances?.[2]?.result as bigint) || 0),
      fluid: BigInt((readBalances?.[3]?.result as bigint) || 0),
    });
  }, [readBalances]);

  /// EpochData
  const { data: readEpoch } = useContractReads({
    contracts: [
      ...epochCalls.map((key) => ({
        ...daoContract,
        functionName: key,
        args: [data.epoch || 0],
      })),
      {
        ...ticketsContract,
        functionName: "totalSupply",
        args: [data.epoch || 0],
      },
      {
        ...ticketsContract,
        functionName: "balanceOf",
        args: [userAddress || zeroAddress, data.epoch || 0],
      },
    ],
    watch: true,
  });

  const [epoch, setEpochData] = useState(nullEpoch);
  useEffect(() => {
    const epochData: any = {};
    readEpoch?.map(
      (res, i) =>
        (epochData[[...epochCalls, "totalSupply", "balanceOf"][i]] = res.result
          ? res.result
          : BigInt(0))
    );
    setEpochData({ ...epochData });
  }, [readEpoch, publicClient]);

  const [time, setTime] = useState<BigInt>(BigInt(0));
  useEffect(() => {
    const func = async () => {
      // @ts-ignore
      const block = await publicClient.getBlockNumber();
      setTime(BigInt(block));
    };
    if (publicClient) func();
  }, [publicClient]);

  /// EpochData
  const { data: readTokens } = useContractReads({
    contracts: [
      ...tokenCalls.map((key) => ({
        ...lucky8Contract,
        functionName: key,
      })),
    ],
    watch: true,
  });
  const [token, setToken] = useState({ totalSupply: BigInt(0) });
  useEffect(() => {
    const tokenData: any = {};
    readTokens?.map(
      (res, i) =>
        (tokenData[[...tokenCalls][i]] = res.result ? res.result : BigInt(0))
    );
    setToken({ ...tokenData });
  }, [readTokens, publicClient]);

  // Pricing
  const lpContract = {
    abi: LP_TOKEN,
    address: LP,
  };
  const { data: mCall } = useContractReads({
    contracts: [
      {
        ...lpContract,
        functionName: "totalSupply",
      },
      {
        ...lpContract,
        functionName: "getReserves",
      },
    ],
  }) as { data: [{ result: bigint }, { result: bigint[]; status: string }] };

  const reserves = mCall?.[1].result || [BigInt(0), BigInt(0)];

  const price =
    mCall && !isNaN(Number(reserves[1]) / Number(reserves[0]))
      ? (Number(reserves[1]) / Number(reserves[0])) * 1e12
      : 0.001;

  const values = useMemo(
    () => ({
      data,
      time,
      epoch,
      price,
      token,
      balances,
    }),
    [data, time, epoch, token, balances, price]
  );

  return (
    // the Provider gives access to the context to its children
    // @ts-ignore
    <UseDappContext.Provider value={{ ...values }}>
      {children}
    </UseDappContext.Provider>
  );
};

export function useDapp() {
  const dappContext = useContext(UseDappContext);
  if (dappContext === null) {
    throw new Error(
      "useDapp() can only be used inside of <UseDappProvider />, " +
        "please declare it at a higher level."
    );
  }
  const { data, time, epoch, token, balances, price } = dappContext;

  return useMemo(
    () => ({ data, time, epoch, token, balances, price }),
    [data, time, epoch, token, balances, price]
  );
}

export default useDapp;
