import { NavLink } from "react-router-dom";
import { UniswapLogo } from "../Logos";
import Section from "../Section";
import { Ticket, TicketRed } from "../Ticket";
import { LUCKY8 } from "@/constants/addresses";

export default function CallToAction() {
  return (
    <Section>
      <div>
        <div className="max-w-full break-words text-center flex flex-col items-center">
          <h2 className="text-4xl font-bold mb-6">Get Started Now</h2>
          <p className="text-md mb-10">
            Is there any way to actually <br />
            lose in Lucky8?!
          </p>
          <div className="grid grid-cols-2 gap-4">
            <NavLink
              to={`https://app.uniswap.org/#/swap?theme=dark&inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=${LUCKY8}`}
              className="bg-white text-black px-6 py-3 rounded-3xl flex items-center justify-center hover:shadow-xl shadow-white"
            >
              <span>Uniswap</span> <UniswapLogo className="h-8 inline ml-2" />
            </NavLink>

            <NavLink
              to="/overview"
              className="bg-primary  text-black px-6 py-3 rounded-3xl hover:shadow-xl shadow-primary"
            >
              Get Started →
            </NavLink>
          </div>
          <div className="w-[250px] h-[300px] relative mt-16">
            <div className="rotate-[-18deg] absolute -left-12">
              <TicketRed />
            </div>
            <div className="rotate-[15deg] absolute top-24 -right-12">
              <Ticket />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
