import Section from "@/components/Section";
import Layout from "../components/Layout";
import Card, { CardHeader } from "@/components/Card";

import { LinkIcon, NorthEast, USDCLogo } from "@/components/Logos";

export type SWReturn = {
  data: {
    referrers: {
      referrer: string;
      referredCount: number;
      referredTokensLocked: number;
      referredLpTokensLocked: number;
    }[];
    lotteryParticipants: {
      address: string;
      referral: string;
      tokensLocked: number;
      lpTokensLocked: number;
    }[];
  };
  error: any;
};

export default function Leaderboard() {
  // const { data, error } =
  //   (useSWR(
  //     `{
  //       referrers(
  //         orderBy: referredTokensLocked,
  //         orderDirection: desc,
  //         first: 100
  //       ) {
  //         referrer
  //         referredCount
  //         referredTokensLocked
  //         referredLpTokensLocked
  //       }
  //       lotteryParticipants(
  //          orderBy: tokensLocked,
  //         orderDirection: desc,
  //         first: 100) {
  //         address
  //         referral
  //         tokensLocked
  //         lpTokensLocked
  //       }
  //     }`,
  //     fetcher
  //   ) as SWReturn) || undefined;

  // console.log(data);
  // const mergedReferrers =
  //   data &&
  //   data.lotteryParticipants.map((obj) => {
  //     const referrer = data.referrers.find(
  //       (referrer) => referrer.referrer === obj.address
  //     );
  //     console.log(referrer);
  //     const tokensLocked =
  //       BigInt(obj?.tokensLocked || 0) + BigInt(obj?.lpTokensLocked || 0);
  //     const bonusMul =
  //       referrer && tokensLocked > 0
  //         ? Math.min(
  //             (referrer.referredTokensLocked +
  //               referrer.referredLpTokensLocked) /
  //               Number(tokensLocked),
  //             10
  //           )
  //         : 1;

  //     return {
  //       ref: obj.address,
  //       bonusMul,
  //       tokenBonus: bonusMul * Number(tokensLocked),
  //       tokensLocked,
  //       totalTokens: bonusMul * Number(tokensLocked) + Number(tokensLocked),
  //     };
  //   });

  // const orderedReferrers = mergedReferrers
  //   ? mergedReferrers
  //       .filter((obj) => obj.tokensLocked > BigInt(0))
  //       .sort((a: any, b: any) => b.totalTokens - a.totalTokens)
  //   : undefined;

  // const totalTickets = Math.floor(
  //   orderedReferrers?.reduce(
  //     (a, c) => a + (Number(c.tokensLocked) + Number(c.tokenBonus)) / 1e18,
  //     0
  //   ) || 0
  // );
  // console.log(totalTickets);
  // console.log(orderedReferrers);

  // const copyLink = (address: string) => {
  //   console.log("Copied address:", address);
  //   toast.success("Address Copied!");
  //   navigator.clipboard.writeText(address);
  // };

  return (
    <Layout>
      <Section className="max-w-5xl" star grad>
        <h1 className="text-3xl font-bold">Leaderboard</h1>
        {/* <div className="w-64 h-64 absolute -top-12 left-1/2 shadow-2xl shadow-primary" /> */}
        {/* Leaderboard */}
        <Card className="">
          <div className="flex justify-between">
            <div className="grid auto-cols-max grid-flow-col gap-6">
              <div>
                <p className="text-sm font-semibold mb-1">
                  {"Total Ticket Supply"}
                </p>
                {/* <p className="text-3xl font-bold text-primary">
                  {formatNum(totalTickets || 0)}
                </p> */}
                {/* <NextDraw /> */}
              </div>
              <div>
                <p className="text-sm font-semibold mb-1">{"Prize Pot"}</p>
                <div className="flex items-center">
                  <USDCLogo className="h-6 mr-1" />
                  <span className="text-3xl font-bold ">
                    {/* {Prize &&
                      formatNum(
                        parseFloat(formatUnits(Prize.value, Prize.decimals))
                      )} */}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="h-18">
              <input
                className="bg-background px-4 py-2 rounded-2xl focus-within:outline-none focus-within:ring-2 focus-within:ring-primary"
                placeholder="Search"
              />
            </div> */}
          </div>
          {/* {orderedReferrers && (
            <div className="max-h-96 h-96 overflow-y-scroll mt-5">
              <Table className="mt-2">
                <TableHeader>
                  <TableRow className="hover:bg-inherit  border-b-white/10">
                    <TableHead>#</TableHead>
                    <TableHead className="w-[100px]">Address</TableHead>
                    <TableHead>Tickets</TableHead>
                    <TableHead>Referral Boost</TableHead>
                    <TableHead>% of Tickets</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {orderedReferrers.map(
                    ({ ref, tokenBonus, bonusMul, tokensLocked }, i) => (
                      <TableRow
                        key={ref}
                        className="hover:bg-inherit border-b-transparent"
                      >
                        <TableCell className="py-3">#{i + 1}</TableCell>
                        <TableCell
                          className="cursor-pointer font-medium py-3"
                          onClick={() => copyLink(ref)}
                        >
                          {ref.slice(0, 8) + "..." + ref.slice(-6)}
                        </TableCell>
                        <TableCell className="py-3">
                          {formatNum(
                            (tokenBonus + Number(tokensLocked)) / 1e18
                          )}
                        </TableCell>
                        <TableCell className="py-3">
                          {formatNum(Number(bonusMul * 100))}%
                        </TableCell>
                        <TableCell className="py-3">
                          {(
                            (Math.floor(
                              (tokenBonus + Number(tokensLocked)) / 1e18
                            ) /
                              totalTickets) *
                            100
                          ).toFixed(2)}
                          %
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          )} */}
        </Card>

        {/* <UniswapCard /> */}
      </Section>
    </Layout>
  );
}
