import Section from "@/components/Section";
import Layout from "../components/Layout";
import Card from "@/components/Card";
import { Link, NavLink } from "react-router-dom";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  usePublicClient,
} from "wagmi";

import useDapp from "@/contexts/useDapp";
import { DAO } from "@/constants/addresses";
import { DAO_ABI } from "@/constants/abis";
import { useEffect, useState } from "react";
import SimpleButton from "@/components/SimpleButton";
import toast from "react-hot-toast";
import { EtherscanLogo } from "@/components/Logos";
import { zeroAddress } from "viem";
import { formatNum } from "@/lib/utils";

export default function Governance() {
  const { data, balances, epoch } = useDapp();
  const { address } = useAccount();

  const publicClient = usePublicClient();
  const [proposals, setProposals] = useState([]);

  useEffect(() => {
    const getProps = async () => {
      // @ts-ignore
      const logs = await publicClient.getContractEvents({
        address: DAO,
        abi: DAO_ABI,
        eventName: "Proposal",
        fromBlock: BigInt(18849244),
      });
      //@ts-ignore
      setProposals(logs);
    };
    if (address) getProps();
  }, [address]);

  const [candiate, setCandiate] = useState<string>(``);

  return (
    <Layout>
      <Section className="max-w-5xl" grad>
        <div className="flex justify-between items-baseline">
          <div className="flex gap-3 items-baseline">
            <h1 className="text-3xl font-bold">Governance</h1>
            {/* <p className="text-primary">{`(${formatNum(userTickets)})`}</p> */}
          </div>
          <h2 className="text-lg">
            Epoch: <span className="text-primary">{Number(data.epoch)}</span>
          </h2>
        </div>

        {/* <ContextCard /> */}

        {/* Leaderboard */}
        <Card className="min-h-[150px]">
          <h3 className="text-2xl font-bold">History</h3>

          {proposals.map((proposal: any) => (
            <Proposal
              proposal={proposal}
              address={address || zeroAddress}
              balances={balances}
            />
          ))}
        </Card>
        <Card className="min-h-[150px]">
          <h3 className="text-2xl font-bold">Propose Candiate</h3>
          <div className="flex mt-3">
            <div
              className={`flex flex-grow rounded-xl outline outline-1 outline-white/30 py-1 px-1 justify-between items-center`}
            >
              <div className="flex flex-col items-start pl-2">
                <input
                  className="w-[380px]  md:w-[450px] bg-transparent block text-white text-lg py-1 focus-within:outline-none focus-within:none focus-within:nones"
                  placeholder="0x"
                  type="string"
                  value={candiate}
                  onChange={(e) => setCandiate(e.target.value)}
                />
              </div>
            </div>
            <VoteButton
              candidate={candiate}
              vote={1}
              title="Propose"
              clear={() => setCandiate(``)}
            />
          </div>
        </Card>
      </Section>
    </Layout>
  );
}

const Proposal = ({
  proposal,
  address,
  balances,
}: {
  proposal: any;
  address: string;
  balances: any;
}) => {
  const publicClient = usePublicClient();

  const [votes, setVotes] = useState<any[]>([]);

  useEffect(() => {
    const getProps = async () => {
      // @ts-ignore
      const logs = await publicClient.getContractEvents({
        address: DAO,
        abi: DAO_ABI,
        eventName: "Vote",
        fromBlock: BigInt(18849244),
        args: { account: (address as `0x${string}`) || zeroAddress },
      });

      setVotes(logs.map((log: any) => log.args) as any[]);
    };
    if (address) getProps();
  }, [address]);

  const [totalVotes, setTotalVotes] = useState<{
    for: number;
    against: number;
  }>({ for: 0, against: 0 });

  useEffect(() => {
    const getProps = async () => {
      // @ts-ignore
      const logs = await publicClient.getContractEvents({
        address: DAO,
        abi: DAO_ABI,
        eventName: "Vote",
        fromBlock: BigInt(18849244),
        args: { candidate: proposal.args.candidate },
      });
      console.log(logs);
      let forVotes = 0;
      let againstVotes = 0;
      logs.forEach((log: any) => {
        if (log.args.vote === 1) forVotes += Number(log.args.bonded) / 1e18;
        else againstVotes += Number(log.args.bonded) / 1e18;
      });
      console.log(forVotes, againstVotes);
      //   setVotes(logs.map((log: any) => log.args) as any[]);
      setTotalVotes({ for: forVotes, against: againstVotes });
    };
    if (address) getProps();
  }, [address]);

  return (
    <div className="flex mt-3 w-full justify-between">
      <div>
        <p className="text-md text-slate-400">{"Candidate"}</p>
        <p className="text-lg font-bold flex items-center">
          {proposal.args.candidate.slice(0, 6) +
            `...` +
            proposal.args.candidate.slice(
              proposal.args.candidate.length - 4,
              proposal.args.candidate.length
            )}
          <Link
            to={`https://etherscan.io/address/${proposal.args.candidate}`}
            target="_blank"
            className="bg-slate-800 rounded-lg px-2 py-2 ml-3 hover:bg-slate-700"
          >
            <EtherscanLogo />
          </Link>
        </p>
      </div>
      {/* <div>
                <p className="text-md text-slate-400">{"Proposer"}</p>
                <p className="text-lg font-bold flex items-center">
                  {proposal.from.slice(0, 6) +
                    `...` +
                    proposal.from.slice(
                      proposal.from.length - 4,
                      proposal.from.length
                    )}
                  <Link
                    to={`https://etherscan.io/address/${proposal.from}`}
                    target="_blank"
                    className="bg-slate-800 rounded-lg px-2 py-2 ml-3 hover:bg-slate-700"
                  >
                    <EtherscanLogo />
                  </Link>
                </p>
              </div> */}
      <div>
        <p className="text-md text-slate-400">{"Start Epoch"}</p>
        <p className="text-lg font-bold flex items-center">
          {Number(proposal.args.start)}
        </p>
      </div>
      <div>
        <p className="text-md text-slate-400">{"End Epoch"}</p>
        <p className="text-lg font-bold flex items-center">
          {Number(proposal.args.start + proposal.args.period)}
        </p>
      </div>
      <div>
        <p className="text-md text-slate-400">{"Votes For / Against"}</p>
        <p className="text-lg font-bold flex items-center">
          {`${formatNum(totalVotes.for)} / ${formatNum(totalVotes.against)}`}
        </p>
      </div>

      <div className="flex">
        {Number(balances.bonded) === 0 ? (
          <SimpleButton
            color={"bg-gray-600"}
            className={`text-white py-3 ml-3 h-fit`}
            disabled={true}
          >
            {"No 888 Bonded"}
          </SimpleButton>
        ) : votes.find((vote) => vote.candidate === proposal.args.candidate) ? (
          <SimpleButton
            color={"bg-gray-600"}
            className={`text-white py-3 ml-3 h-fit`}
            disabled={true}
          >
            {"Already Voted"}
          </SimpleButton>
        ) : (
          <>
            <VoteButton
              candidate={proposal.args.candidate}
              vote={1}
              title="Vote For"
              clear={() => null}
            />
            <VoteButton
              candidate={proposal.args.candidate}
              vote={0}
              title="Vote Against"
              clear={() => null}
            />
          </>
        )}
      </div>
    </div>
  );
};

const VoteButton = ({
  candidate,
  vote,
  title,
  clear,
}: {
  candidate: string;
  vote: number;
  title: string;
  clear: () => void;
}) => {
  // Write REGISTER CODE
  const { config, error } = usePrepareContractWrite({
    address: DAO,
    abi: DAO_ABI,
    functionName: "vote",
    args: [candidate as `0x${string}`, vote],
  });
  const { data: txReceipt, write } = useContractWrite(config);

  useEffect(() => {
    if (txReceipt) {
      toast.success("Vote Sent!");
      clear();
    }
  }, [txReceipt, clear]);

  return (
    <SimpleButton
      color={error ? "bg-gray-600" : `bg-primary`}
      className={`${error ? "text-white" : `text-black`} py-3 ml-3 h-fit`}
      disabled={error ? true : false}
      onClick={() => write && write()}
    >
      {title}
    </SimpleButton>
  );
};
