export default function Card({
  children,
  color,
  className,
}: {
  children: React.ReactNode;
  color?: string;
  className?: string;
}) {
  return (
    <div
      className={`w-full ${
        color || "bg-foreground"
      } relative rounded-xl p-6 mt-6 ${className || ""}`}
    >
      {children}
    </div>
  );
}

export const CardHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`flex justify-between ${className || ""}`}>{children}</div>
  );
};
