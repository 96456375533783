import "./App.css";

import "@rainbow-me/rainbowkit/styles.css";
import {
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { localhost, mainnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { alchemyProvider } from "wagmi/providers/alchemy";

import { RouterProvider, Link, createHashRouter } from "react-router-dom";

import Overview from "./pages/Overview";
import Home from "./pages/Home";
import Leaderboard from "./pages/Leaderboard";
import PrizePot from "./pages/PrizePot";

import { Toaster } from "react-hot-toast";
import { DappProvider } from "./contexts/useDapp";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import Migrate from "./pages/Migrate";
import Lottery from "./pages/Lottery";
import Governance from "./pages/Governance";

const { chains, publicClient } = configureChains(
  [mainnet],
  [
    // jsonRpcProvider({
    //   rpc: () => ({
    //     http: `http://localhost:8545`,
    //   }),
    // }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Lucky8",
  projectId: "a5b301a9834b2f9d4f83397b89674613",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/overview",
    element: <Overview />,
  },
  {
    path: "/lottery",
    element: <Lottery />,
  },
  {
    path: "/leaderboard",
    element: <Leaderboard />,
  },
  {
    path: "/prize-pot",
    element: <PrizePot />,
  },
  {
    path: "/migration",
    element: <Migrate />,
  },
  {
    path: "/governance",
    element: <Governance />,
  },
]);

function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider theme={darkTheme()} chains={chains}>
          <DappProvider>
            <RouterProvider router={router} fallbackElement={<NoMatch />} />
          </DappProvider>
        </RainbowKitProvider>
        <Toaster position="bottom-right" />
      </WagmiConfig>
    </>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
