export default function SimpleButton({
  children,
  color,
  className,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  color?: string;
  className?: string;
  disabled?: boolean;
}) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${
        color || "bg-slate-800"
      } px-4 py-2 rounded-lg flex items-center justify-center w-fit ${
        className || ""
      }`}
    >
      {children}
    </button>
  );
}
