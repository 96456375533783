import { Logo, Logomark } from "../Logos";

export function Ticket({ color }: { color?: string }) {
  return (
    <div className="shadow-2xl shadow-primary bg-primary rounded-2xl py-3 flex flex-col items-center h-60 w-48 relative outline outline-background overflow-hidden">
      <Logo className="w-20" fill="#101216" />
      <Logomark
        className="w-52 h-52 absolute -translate-x-1/2 -bottom-1/4 left-1/2"
        fill="#101216"
      />
    </div>
  );
}

export function TicketRed({ color }: { color?: string }) {
  return (
    <div className="shadow-2xl shadow-primary bg-[#1D1D1D] rounded-2xl py-3 flex flex-col items-center h-60 w-48 relative outline outline-[#1D1D1D] overflow-hidden">
      <Logo className="w-20" fill="#AB2417" />
      <Logomark
        className="w-52 h-52 absolute -translate-x-1/2 -bottom-1/4 left-2/3"
        fill="#AB2417"
      />
    </div>
  );
}

export function TicketGrey({
  className,
  text,
  bg,
  sh,
}: {
  text?: string;
  bg?: string;
  sh?: string;
  className?: string;
}) {
  return (
    <div
      className={`shadow-2xl ${sh || "shadow-white/20"} ${
        bg || "bg-[#363636]"
      } rounded-2xl py-0 flex flex-col items-center h-72 w-64 relative overflow-hidden ${
        className || ""
      }`}
    >
      <Logo className="w-20" fill={text || "white"} />
      <span className={`h-[1px] w-full bg-${text || "white"}/50`} />
      <Logomark
        className="w-[265px] h-[265px] absolute -translate-x-1/2 -bottom-[90px] left-1/2"
        fill={text || "white"}
      />
    </div>
  );
}
