import Section from "../Section";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export default function FAQs() {
  return (
    <Section light>
      <h2 className="text-3xl mb-16">FAQ</h2>
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="item-1" className="px-2 border-b-[#363636]">
          <AccordionTrigger className="hover:no-underline py-8">
            {`Can everyone participate in a lottery draw?`}
          </AccordionTrigger>
          <AccordionContent>
            {`Everyone who holds lottery tickets will be able to participate 
            in the lottery. Bond $888 to be able to mint lottery tickets.`}
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2" className="px-2 border-b-[#363636]">
          <AccordionTrigger className="hover:no-underline py-8">
            {`How often will a lottery draw happen?`}
          </AccordionTrigger>
          <AccordionContent>
            {`The lottery is defined in so called epochs. One epoch lasts 
            24 hours, and at the end of every epoch one lottery draw takes place.`}
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-3" className="px-2 border-b-[#363636]">
          <AccordionTrigger className="hover:no-underline py-8">
            {`What is the utility of $888?`}
          </AccordionTrigger>
          <AccordionContent>
            {`$888 is Lucky8's native protocol token that enables you to mint lottery tickets, 
            and hence participate in the lottery by bonding it. Additionally, as 
            Lucky8 is a fully decentralized DAO, $888 is used for governance. On top of 
            that, everyone that bonds $888 will receive native bonding yield.`}
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-4" className="px-2 border-b-[#363636]">
          <AccordionTrigger className="hover:no-underline py-8">
            {`What is the best strategy to increase my odds of winning for the lottery?`}
          </AccordionTrigger>
          <AccordionContent>
            {`The best strategy is to remain bonded for as long as possible to continuously auto-compound more and more $888 rewards every epoch:`}
            <br />
            {`1. Bond $888 tokens`}
            <br />
            {`2. Stay bonded!`}

            <br />
            <br />
            {`And don't forget to mint the lottery tickets!`}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Section>
  );
}
