import { TicketGrey } from "../Ticket";

export default function Section({
  children,
  light,
  className,
  grad,
  star,
  circ,
  winner,
}: {
  children: React.ReactNode;
  light?: boolean;
  star?: boolean;
  grad?: boolean;
  circ?: boolean;
  winner?: boolean;
  className?: string;
}) {
  return (
    <section
      className={`w-full ${
        light ? "bg-[#1C1F26]" : "bg-background"
      } pb-24 pt-14 overflow-hidden relative min-h-[calc(100vh-161px)] px-4 `}
      style={
        grad
          ? {
              backgroundImage:
                "radial-gradient(rgba(240,202,74,0.15) 0%, rgba(0,0,0,0) 70%)",
            }
          : {}
      }
    >
      <div className={` mx-auto max-w-4xl  z-[2] relative ${className || ``}`}>
        {children}
      </div>
      {star && (
        <svg
          width="1440"
          height="1122"
          viewBox="0 0 1440 1122"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className=" w-[1440] h-[1122] absolute translate-y-1/2 -translate-x-1/2 bottom-2/3 left-1/2 z-0"
        >
          <mask
            id="mask0_871_4324"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="-472"
            y="-501"
            width="2363"
            height="2363"
          >
            <rect
              x="-472"
              y="-501"
              width="2362.18"
              height="2362.18"
              fill="#B43636"
            />
          </mask>
          <g mask="url(#mask0_871_4324)">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="360 707.244 678"
              to="0 707.244 678"
              dur="55s"
              additive="sum"
              repeatCount="indefinite"
            />
            <path
              d="M-457.807 451.99L-437.505 -32.9566L719.594 695.589L-457.807 451.99Z"
              fill="#F0CA4A"
            />
            <path
              d="M1908.07 407.698L1816 -110.909L723.424 697.439L1908.07 407.698Z"
              fill="#F0CA4A"
            />
            <path
              d="M1233.44 1638.41L1787.21 1544.72L723.625 698.591L1233.44 1638.41Z"
              fill="#F0CA4A"
            />
            <path
              d="M741.755 -331.783L1050.41 -384L723.284 695.589L741.755 -331.783Z"
              fill="#F0CA4A"
            />
            <path
              d="M-359.339 1350.02L-196.462 1555.95L740.497 686.362L-359.339 1350.02Z"
              fill="#F0CA4A"
            />
          </g>
        </svg>
      )}
      {winner && (
        <>
          <TicketGrey
            bg={"bg-black"}
            text="#F0CA4A"
            className="absolute translate-y-1/2 left-1/2 -translate-x-1/2 z-[1] rotate-[-18deg] h-72 w-64 drop-shadow-2xl"
          />
          <svg
            width="1440"
            height="1122"
            viewBox="0 0 1440 1122"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-[1440] h-[1122] absolute translate-y-1/2 -translate-x-1/2 bottom-1/4 left-1/2 z-0"
          >
            <mask
              id="mask0_871_4324"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="-472"
              y="-501"
              width="2363"
              height="2363"
            >
              <rect
                x="-472"
                y="-501"
                width="2362.18"
                height="2362.18"
                fill="#B43636"
              />
            </mask>
            <g mask="url(#mask0_871_4324)" transform="rotate(175 707.244 678)">
              {/* <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="360 707.244 678"
                to="0 707.244 678"
                dur="80s"
                additive="sum"
                repeatCount="indefinite"
              /> */}
              <path
                d="M-457.807 451.99L-437.505 -32.9566L719.594 695.589L-457.807 451.99Z"
                fill="#F0CA4A"
              />
              <path
                d="M1908.07 407.698L1816 -110.909L723.424 697.439L1908.07 407.698Z"
                fill="#F0CA4A"
              />
              <path
                d="M1233.44 1638.41L1787.21 1544.72L723.625 698.591L1233.44 1638.41Z"
                fill="#F0CA4A"
              />
              <path
                d="M741.755 -331.783L1050.41 -384L723.284 695.589L741.755 -331.783Z"
                fill="#F0CA4A"
              />
              <path
                d="M-359.339 1350.02L-196.462 1555.95L740.497 686.362L-359.339 1350.02Z"
                fill="#F0CA4A"
              />
            </g>
          </svg>
        </>
      )}
      {circ && (
        <svg
          className="w-full absolute top-[calc(50%+80px)] -z-0 left-1/2 -translate-x-1/2"
          viewBox="0 0 100 100"
          fill="#F0CA4B"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="50" cy="50" r="50" />
        </svg>
      )}
    </section>
  );
}
