import { Logo, USDCLogo, UniswapLogo } from "../Logos";
import { Ticket, TicketRed } from "../Ticket";
import { LUCKY8 } from "@/constants/addresses";
import { formatNum } from "@/lib/utils";
import { formatUnits } from "viem";
import { NavLink } from "react-router-dom";
import useDapp from "@/contexts/useDapp";

export default function Hero() {
  const { data } = useDapp();

  return (
    <section className="h-[calc(100vh-80px)] flex flex-col justify-center items-center box-border overflow-hidden relative">
      <div className="max-w-full break-words text-center flex flex-col items-center">
        <Logo className="w-96 mb-8" />
        <p className="text-md mb-8">The No-Loss Lottery.</p>
        <div className="flex flex-col mb-10">
          <span className="text-md text-primary mb-2">Current Prize Pot</span>
          <div className="text-2xl font-bold flex items-center">
            <USDCLogo className="h-8 mr-2" />
            {formatNum(
              parseFloat(
                formatUnits((data.totalPrizePool as bigint) || BigInt(0), 6)
              )
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={() =>
              window.open(
                `https://app.uniswap.org/#/swap?theme=dark&slippage=1200&inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=${LUCKY8}`,
                "_blank"
              )
            }
            className="bg-white text-black px-6 py-3 rounded-3xl flex items-center justify-center hover:shadow-xl shadow-white"
          >
            <span>Uniswap</span> <UniswapLogo className="h-6 inline ml-2" />
          </button>
          <NavLink to="/overview">
            <button className="bg-primary text-black px-6 py-3 rounded-3xl hover:shadow-xl shadow-primary">
              Get Started →
            </button>
          </NavLink>
        </div>
        <div className="w-[250px] h-[250px] relative mt-16">
          <div className="rotate-[-18deg] absolute -left-12">
            <TicketRed />
          </div>
          <div className="rotate-[15deg] absolute top-24 -right-12">
            <Ticket />
          </div>
        </div>
      </div>

      <svg
        viewBox="0 0 1440 1000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className=" w-[1400px] h-[1000px] absolute translate-y-1/2 -translate-x-1/2 bottom-1/2 left-1/2 z-[-1]"
      >
        <path
          d="M-113.916 644.339L-200.346 379.821L803.279 794.109L-113.916 644.339Z"
          fill="#F0CA4A"
        />
        <path
          d="M1707.4 578.055L1666.15 356.321L803.653 794.663L1707.4 578.055Z"
          fill="#F0CA4A"
        />
        <path
          d="M1246.79 1610.79L1728.12 1529.36L803.655 793.904L1246.79 1610.79Z"
          fill="#F0CA4A"
        />
        <path
          d="M-321.845 1040.32L-111.496 1270.41L803.286 794.322L-321.845 1040.32Z"
          fill="#F0CA4A"
        />
        <path
          d="M1167.37 -123.292L1364.66 -132.678L802.837 794.705L1167.37 -123.292Z"
          fill="#F0CA4A"
        />
      </svg>
    </section>
  );
}
