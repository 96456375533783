import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const USformatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const NumberFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
});

export const formatUSD = (value: number) => USformatter.format(value);
export const formatNum = (value: number | bigint | BigInt, decimals?: number) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimals || 2,
    maximumFractionDigits: decimals || 2,
  }).format(Number(value));
