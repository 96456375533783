import { Link, NavLink } from "react-router-dom";
import Layout from "../components/Layout";
import Section from "@/components/Section";
import { formatNum, formatUSD } from "@/lib/utils";
import { EtherscanLogo, NorthEast } from "@/components/Logos";
import Card, { CardHeader } from "@/components/Card";
import SimpleButton from "@/components/SimpleButton";
import UniswapCard from "@/components/UniswapCard";

import { DAO, LUCKY8 } from "@/constants/addresses";

import ContextCard from "@/components/ContextCard";
import Countdown from "react-countdown";
import InputAction from "@/components/InputAction";
import { DAO_ABI } from "@/constants/abis";
import useDapp from "../contexts/useDapp";
import { formatUnits } from "viem";
import { CURRENT_EPOCH_PERIOD, CURRENT_EPOCH_START } from "@/constants/config";

export default function Overview() {
  const { data, balances, epoch, price } = useDapp();

  return (
    <Layout>
      <Section className="max-w-5xl">
        <div className="flex justify-between items-baseline">
          <h1 className="text-3xl font-bold">Overview</h1>
          <h2 className="text-md text-primary">
            {`Lottery draws are claimable after each Epoch`}
          </h2>
        </div>
        <ContextCard />
        {/* First Row */}
        <Card color="bg-foreground" className={`mt-6`}>
          <div className="grid grid-rows-3 sm:grid-rows-2 sm:grid-cols-3 grid-cols-2 lg:grid-rows-1 lg:grid-cols-5 gap-1">
            <div>
              <p className="text-sm text-slate-400 mb-2">{"Epoch"}</p>
              <p className="text-4xl text-primary">{Number(data.epoch)}</p>
            </div>
            <Statistic
              title={"Balance"}
              number={Number(balances.wallet) / 1e18}
              value={(Number(balances.wallet) / 1e18) * price}
            />
            <Statistic
              title={"Bonded"}
              number={Number(balances.bonded) / 1e18}
              value={(Number(balances.bonded) / 1e18) * price}
            />
            <Statistic
              title={"Staged"}
              number={Number(balances.staged) / 1e18}
              value={(Number(balances.staged) / 1e18) * price}
            />

            <div>
              <p className="text-sm text-slate-400 mb-2">
                {"Your Tickets for this Epoch"}
              </p>
              {balances.bonded > 0 && epoch.balanceOf === BigInt(0) ? (
                <SimpleButton color="bg-primary">
                  <NavLink to="/lottery">
                    <span className="text-black">Mint Tickets Now</span>
                  </NavLink>
                </SimpleButton>
              ) : (
                <p className="text-4xl text-primary">
                  {formatNum(epoch.balanceOf, 0)}
                </p>
              )}
            </div>
          </div>
        </Card>
        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 w-full">
          {/* Token Card */}
          <Card>
            <CardHeader>
              <div className="flex items-center">
                <h3 className="text-lg">Bond $888</h3>
                <Link
                  to={`https://etherscan.io/address/${DAO}`}
                  target="_blank"
                  className="bg-slate-800 rounded-lg px-2 py-2 ml-3 hover:bg-slate-700"
                >
                  <EtherscanLogo />
                </Link>
              </div>
              <div className="flex items-center justify-end gap-2">
                <SimpleButton
                  color={"bg-[#5F022F]"}
                  onClick={() =>
                    window.open(
                      `https://app.uniswap.org/#/swap?theme=dark&slippage=1200&inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=${LUCKY8}`,
                      "_blank"
                    )
                  }
                >
                  Uniswap
                  <NorthEast />
                </SimpleButton>
                {/* <NavLink to="/stake">
                  <SimpleButton color="bg-slate-800">
                    Stake <Layers className="w-4 ml-1" />
                  </SimpleButton>
                </NavLink> */}
              </div>
            </CardHeader>
            <div className="mt-6 grid auto-cols-max grid-flow-col gap-6">
              <Statistic
                title={"Bonded"}
                number={Number(formatUnits(balances.bonded, 18))}
                value={0}
              />

              <div>
                <p className="text-md text-slate-400">{"Exit Lockup"}</p>
                <p className="text-lg font-semibold text-primary">
                  {"2 Epochs"}
                </p>
              </div>
            </div>

            {/* DEPOSIT */}
            <InputAction
              balance={balances.wallet}
              address={DAO}
              approvee={LUCKY8}
              abi={DAO_ABI}
              name={"Bond"}
              functionName={"depositAndBond"}
              inline
            />
            {/* BOND */}
            <InputAction
              balance={balances.bonded}
              address={DAO}
              abi={DAO_ABI}
              name={"Unbond"}
              functionName={"unbondUnderlying"}
              inline
            />
          </Card>
          {/* Tickets Card */}
          <Card>
            <CardHeader>
              <div className="flex items-center">
                <h3 className="text-lg">Stage $888</h3>
              </div>
              <div className="flex items-center justify-end gap-2">
                <SimpleButton
                  color={"bg-[#5F022F]"}
                  onClick={() =>
                    window.open(
                      `https://app.uniswap.org/#/swap?theme=dark&slippage=1200&inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=${LUCKY8}`,
                      "_blank"
                    )
                  }
                >
                  Uniswap
                  <NorthEast />
                </SimpleButton>
              </div>
            </CardHeader>

            <div className="mt-6 grid auto-cols-max grid-flow-col gap-6">
              <Statistic
                title={"Staged"}
                number={Number(formatUnits(balances.staged, 18))}
                value={0}
              />

              {balances.staged > 0 && (
                <div>
                  <p className="text-md text-slate-400 ">{"Unlocked in"}</p>
                  <p className="text-lg font-semibold text-primary">
                    <Countdown
                      date={
                        (CURRENT_EPOCH_START +
                          CURRENT_EPOCH_PERIOD * Number(balances.fluid)) *
                        1000
                      }
                    />
                  </p>
                </div>
              )}
            </div>

            {/* STAGE */}
            <InputAction
              balance={balances.staged}
              address={DAO}
              abi={DAO_ABI}
              functionName={"bond"}
              name="Rebond"
              inline
            />
            <InputAction
              balance={balances.staged}
              address={DAO}
              abi={DAO_ABI}
              functionName={"withdraw"}
              inline
            />
          </Card>
        </div>
        <UniswapCard />
      </Section>
    </Layout>
  );
}

const Statistic = ({
  title,
  number,
  numberDecimals = 2,
  value,
}: {
  title: string;
  number: number | bigint;
  numberDecimals?: number;
  value: number;
}) => {
  return (
    <div>
      <p className="text-md text-slate-400">{title}</p>
      <p className="text-lg font-bold">{formatNum(number, numberDecimals)}</p>
      <p className="text-sm text-slate-400">≈{formatUSD(value)}</p>
    </div>
  );
};
