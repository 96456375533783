import { LUCKY8 } from "@/constants/addresses";
import Card, { CardHeader } from "../Card";
import { NorthEast, UniswapLogo } from "../Logos";
import SimpleButton from "../SimpleButton";

export default function UniswapCard({
  color,
  noLogo,
}: {
  color?: string;
  noLogo?: boolean;
}) {
  return (
    <Card className={`overflow-hidden`} color={color ? color : "bg-[#300017]"}>
      <CardHeader>
        <div className="">
          <h3 className="text-lg">Uniswap Liquidity Pool</h3>
          <p className="text-sm text-gray-500">{`Buy $888 - Provide liquidity to the 888/USDC LP pool`}</p>
        </div>
        <div className="flex items-center justify-end gap-2">
          <SimpleButton
            color="bg-white text-black"
            onClick={() =>
              window.open(
                `https://app.uniswap.org/#/swap?theme=dark&slippage=1200&inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=${LUCKY8}`,
                "_blank"
              )
            }
          >
            Access Uniswap <NorthEast color="black" />
          </SimpleButton>
        </div>
      </CardHeader>
      {!noLogo && (
        <UniswapLogo className="w-36 absolute hidden md:block translate-y-1/2 -translate-x-1/2 bottom-1/2 left-1/2 " />
      )}
    </Card>
  );
}
