import Countdown from "react-countdown";
import Card from "../Card";
import { Clock } from "../Logos";
import useDapp from "@/contexts/useDapp";
import { formatNum, formatUSD } from "@/lib/utils";
import { formatUnits } from "viem";
import { CURRENT_EPOCH_START } from "@/constants/config";

export default function ContextCard({ className }: { className?: string }) {
  const { data, price, token, epoch } = useDapp();
  const {
    totalBonded,
    thisEpochsPrizePool,
    thisEpochsTokenRewards,
    totalPrizePool,
  } = data;
  const supplyBonded = (Number(totalBonded) / Number(token.totalSupply)) * 100;

  const nextEpoch = CURRENT_EPOCH_START + Number(data.epoch) * 86400;

  const tokenRewards = Number(thisEpochsTokenRewards) / 1e18;
  const bondedSupply = Number(totalBonded) / 1e18;
  const bondingAPR = Math.pow(1 + tokenRewards / bondedSupply, 365) * 100;

  const PrizeUSD = Number(thisEpochsPrizePool) / 1e6;
  const TotalTickets = Number(epoch.totalSupply);
  const evAPR = (PrizeUSD / (TotalTickets * price)) * 365 * 100;

  return (
    <Card
      color="bg-[#F0CA4A]"
      className={`mt-6 bg-opacity-30 outline outline-primary ${
        className || ""
      }`}
    >
      {/* <div className="grid grid-rows-3 sm:grid-rows-2 sm:grid-cols-3 grid-cols-2 lg:grid-rows-1 lg:grid-cols-6 gap-1"> */}
      <div className="grid grid-rows-3 grid-cols-2  md:flex md:flex-row justify-between items-center">
        <Stat title="Bonding APY" value={`${formatNum(bondingAPR, 2)}%`} />
        <Stat
          title="EV APR"
          value={`${formatNum(isNaN(evAPR) ? 0 : evAPR, 2)}%`}
        />
        <Stat
          title="Next Prize Pot"
          value={`${formatUSD(
            Number(formatUnits(thisEpochsPrizePool as bigint, 6))
          )}`}
        />
        <Stat
          title="Total Prize Pot"
          value={`${formatUSD(
            Number(formatUnits(totalPrizePool as bigint, 6))
          )}`}
        />

        <Stat
          title="% Tickets Minted"
          value={`${formatNum(
            (Number(epoch.totalSupply) / (Number(data.totalBonded) / 1e18)) *
              100,
            0
          )}%`}
        />
        <Stat
          title="% $888 Bonded"
          value={`${isNaN(supplyBonded) ? 0.0 : supplyBonded.toFixed(2)}%`}
        />
        {/* Clock  */}
        <div className="min-w-[115px]">
          <div className="flex items-baseline">
            <Clock />
            <p className="text-sm ml-1">{"Next Draw in"}</p>
          </div>
          <p className="text-lg font-bold text-primary">
            <Countdown date={nextEpoch * 1000} />
          </p>
        </div>
      </div>
    </Card>
  );
}

export const Stat = ({
  title,
  value,
  sub,
}: {
  title: string;
  value: string;
  sub?: string;
}) => {
  return (
    <div>
      {(title && <div className="text-md py-0">{title}</div>) || <></>}
      {(value && (
        <div className="text-lg font-medium text-primary -mt-1">{value}</div>
      )) || <></>}
      {(sub && (
        <div className="text-lg font-medium text-primary -mt-1">{sub}</div>
      )) || <></>}
    </div>
  );
};
