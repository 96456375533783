import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ConnectButton } from "@rainbow-me/rainbowkit";

import { Logo } from "../Logos";
import { NavLink } from "react-router-dom";
import { useAccount, useBalance, useNetwork } from "wagmi";
import addresses from "@/constants/addresses";
import { formatNum } from "@/lib/utils";

const navigation = [
  { name: "Overview", href: "/overview" },
  { name: "Lottery", href: "/lottery" },
  { name: "PrizePot", href: "/prize-pot" },
  // { name: "Leaderboard", href: "/leaderboard" },
  { name: "Governance", href: "/governance" },
  { name: "Token Migration", href: "/migration" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const CustomConnectButton = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
            className="text-black mx-2 bg-primary px-4 py-2 rounded-md "
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    onClick={openConnectModal}
                    type="button"
                    className="flex items-center gap-1"
                  >
                    <span>Connect Wallet</span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_669_2592)">
                        <path
                          d="M14 4.85333V3.33333C14 2.6 13.4 2 12.6667 2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V11.1467C14.3933 10.9133 14.6667 10.4933 14.6667 10V6C14.6667 5.50667 14.3933 5.08667 14 4.85333ZM13.3333 6V10H8.66667V6H13.3333ZM3.33333 12.6667V3.33333H12.6667V4.66667H8.66667C7.93333 4.66667 7.33333 5.26667 7.33333 6V10C7.33333 10.7333 7.93333 11.3333 8.66667 11.3333H12.6667V12.6667H3.33333Z"
                          fill="#101216"
                        />
                        <path
                          d="M10.666 9C11.2183 9 11.666 8.55228 11.666 8C11.666 7.44772 11.2183 7 10.666 7C10.1137 7 9.66602 7.44772 9.66602 8C9.66602 8.55228 10.1137 9 10.666 9Z"
                          fill="#101216"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_669_2592">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    type="button"
                    className="flex items-center gap-1"
                  >
                    Wrong network
                  </button>
                );
              }
              return (
                <div style={{ display: "flex", gap: 12 }}>
                  <button
                    onClick={openAccountModal}
                    type="button"
                    className=" flex items-center gap-1"
                  >
                    <span>{account.displayName}</span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_669_2352)">
                        <path
                          d="M9.59398 7.33341L10.6673 8.40674V7.33341H9.59398ZM11.334 4.66674H8.66732V5.9334H11.334C12.474 5.9334 13.4007 6.86007 13.4007 8.00007C13.4007 8.84674 12.8873 9.58007 12.154 9.8934L13.0873 10.8267C14.034 10.2401 14.6673 9.1934 14.6673 8.00007C14.6673 6.16007 13.174 4.66674 11.334 4.66674ZM1.33398 2.84674L3.40732 4.92007C2.19398 5.4134 1.33398 6.60674 1.33398 8.00007C1.33398 9.84007 2.82732 11.3334 4.66732 11.3334H7.33398V10.0667H4.66732C3.52732 10.0667 2.60065 9.14007 2.60065 8.00007C2.60065 6.94007 3.40732 6.06674 4.44065 5.9534L5.82065 7.33341H5.33398V8.66674H7.15398L8.66732 10.1801V11.3334H9.82065L12.494 14.0067L13.434 13.0667L2.27398 1.90674L1.33398 2.84674Z"
                          fill="#101216"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_669_2352">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default function Navigation() {
  const { address } = useAccount();
  const { isLoading, data: Lucky8Balance } = useBalance({
    watch: true,
    address,
    token: addresses["LUCKY8"],
  });

  return (
    <Disclosure as="nav" className="bg-background">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 md:px-6 lg:px-8">
            <div className="relative flex h-20 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <NavLink to={"/"}>
                    <Logo className="block h-8 w-auto" />
                  </NavLink>
                </div>
                <div className="hidden lg:ml-6 lg:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "text-white"
                              : "text-gray-400 hover:bg-gray-700 hover:text-white",
                            "rounded-md px-1 py-2 text-sm font-medium"
                          )
                        }
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0 hidden lg:flex">
                {address && (
                  <div className="flex flex-col  px-2 py-1 bg-foreground">
                    <span className="text-xs">$888 Balance:</span>
                    <span className="pl-2 w-full text-right text-sm">
                      {Lucky8Balance &&
                        formatNum(parseFloat(Lucky8Balance.formatted))}
                    </span>
                  </div>
                )}
                <CustomConnectButton />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    true
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                >
                  {item.name}
                </NavLink>
              ))}

              <div className="w-fit mx-auto flex">
                {address && (
                  <div className="flex flex-col  px-2 py-1 bg-foreground">
                    <span className="text-xs">$888 Balance:</span>
                    <span className="pl-2 w-full text-right">
                      {Lucky8Balance &&
                        formatNum(parseFloat(Lucky8Balance.formatted))}
                    </span>
                  </div>
                )}
                <CustomConnectButton />
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
