import Layout from "../components/Layout";
import { Clock, USDCLogo } from "@/components/Logos";
import { formatNum, formatUSD } from "@/lib/utils";
import { formatUnits } from "viem";
import useDapp from "@/contexts/useDapp";
import { CURRENT_EPOCH_START } from "@/constants/config";
import Countdown from "react-countdown";

export default function PrizePot() {
  const { data, epoch, price } = useDapp();
  const {
    totalPrizePool,
    totalTokenRewards,
    thisEpochsTokenRewards,
    totalBonded,
    thisEpochsPrizePool,
  } = data;

  const nextEpoch = CURRENT_EPOCH_START + Number(data.epoch) * 86400;

  const tokenRewards = Number(thisEpochsTokenRewards) / 1e18;
  const bondedSupply = Number(totalBonded) / 1e18;
  const bondingAPR = Math.pow(1 + tokenRewards / bondedSupply, 365) * 100;

  const PrizeUSD = Number(thisEpochsPrizePool) / 1e6;
  const TotalTickets = Number(epoch.totalSupply);
  const evAPR = (PrizeUSD / (TotalTickets * price)) * 365 * 100;
  return (
    <Layout>
      <div className="bg-primary flex flex-col text-black justify-center items-center pt-12 overflow-hidden relative min-h-[calc(100vh-161px)] px-4">
        <div className="flex-grow text-3xl md:text-7xl font-bold flex justify-center items-center">
          <USDCLogo className="h-12 md:h-20 mr-2" />
          {formatNum(
            parseFloat(formatUnits((totalPrizePool as bigint) || BigInt(0), 6))
          )}
        </div>
        <div className="relative bottom-10 mt-24 pt-5 border-t-[1px] border-black flex items-center lg:w-[840px]">
          <div className="w-full flex flex-col md:flex-row justify-between">
            <div>
              <h2 className="text-4xl font-semibold">Next Lottery Draw</h2>
              <div className="grid grid-cols-3 grid-rows-2 mt-5 gap-4">
                <div>
                  <p className="text-md text-black">{"Prize Pot"}</p>
                  <p className="text-lg font-bold">
                    {formatUSD(
                      Number(
                        formatUnits(
                          (data.thisEpochsPrizePool as bigint) || BigInt(0),
                          6
                        )
                      )
                    )}
                  </p>
                </div>
                <Statistic
                  title="888 Rewards"
                  number={Number(
                    formatUnits(
                      (data.thisEpochsTokenRewards as bigint) || BigInt(0),
                      18
                    )
                  )}
                />
                {/* Clock  */}
                <div className="min-w-[115px]">
                  <div className="flex items-baseline">
                    <svg
                      className=""
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.995 0C2.235 0 0 2.24 0 5C0 7.76 2.235 10 4.995 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 4.995 0ZM5 9C2.79 9 1 7.21 1 5C1 2.79 2.79 1 5 1C7.21 1 9 2.79 9 5C9 7.21 7.21 9 5 9ZM5.25 2.5H4.5V5.5L7.125 7.075L7.5 6.46L5.25 5.125V2.5Z"
                        fill="black"
                      />
                    </svg>
                    <p className="text-sm ml-1">{"Next Draw in"}</p>
                  </div>
                  <p className="text-lg font-bold text-black">
                    <Countdown date={nextEpoch * 1000} />
                  </p>
                </div>
                {/* <div>
                  <p className="text-md text-black">{"EV APR"}</p>
                  <p className="text-lg font-bold">
                    {formatNum(isNaN(evAPR) ? 0 : evAPR, 2)}%
                  </p>
                </div> */}
                <div>
                  <p className="text-md text-black">{"EV APR"}</p>
                  <p className="text-lg font-bold">
                    {formatNum(isNaN(evAPR) ? 0 : evAPR, 2)}%
                  </p>
                </div>
                <div>
                  <p className="text-md text-black">{"Bonding APR"}</p>
                  <p className="text-lg font-bold">{formatNum(bondingAPR)}%</p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-4xl font-semibold">Total Lottery</h2>
              <div className="grid grid-cols-2 grid-rows-1  mt-5 gap-4">
                <div>
                  <p className="text-md text-black">{"Total Prize Pool"}</p>
                  <p className="text-lg font-bold">
                    {formatUSD(
                      Number(
                        formatUnits(
                          (data.totalPrizePool as bigint) || BigInt(0),
                          6
                        )
                      )
                    )}
                  </p>
                </div>
                <Statistic
                  title="888 Rewards"
                  number={Number(
                    formatUnits((totalTokenRewards as bigint) || BigInt(0), 18)
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const Statistic = ({
  title,
  number,
  numberDecimals = 2,
}: {
  title: string;
  number: number | bigint;
  numberDecimals?: number;
}) => {
  return (
    <div>
      <p className="text-md text-black">{title}</p>
      <p className="text-lg font-bold">{formatNum(number, numberDecimals)}</p>
    </div>
  );
};
