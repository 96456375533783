import Section from "@/components/Section";
import Layout from "../components/Layout";
import Card from "@/components/Card";
import { formatNum, formatUSD } from "@/lib/utils";
import SimpleButton from "@/components/SimpleButton";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { NavLink } from "react-router-dom";
import {
  useAccount,
  useContractReads,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";
import { DAO_ABI, TICKETS_ABI } from "@/constants/abis";
import { DAO, TICKETS } from "@/constants/addresses";
import { formatUnits } from "viem";
import { formatDistance } from "date-fns";
import ContextCard from "@/components/ContextCard";

import useDapp from "@/contexts/useDapp";
import { CURRENT_EPOCH_PERIOD, CURRENT_EPOCH_START } from "@/constants/config";
import { useEffect, useState } from "react";

// Format data
type Round = {
  totalBondedAt: bigint;
  drawExecuted: boolean;
  winnersAt: bigint;
  winningTickets: bigint[];
  prizePerTicket: bigint;
  userTicketRange: bigint[];
  userPrizeClaimed: bigint;
  tickets: bigint;
  epoch: number;
};

export default function Lottery() {
  const { data, balances, epoch } = useDapp();
  const { address } = useAccount();

  // Collect Calls
  let calls: any = [];
  Array(Number(data.epoch) + 1)
    .fill(0)
    .map((_, i) => {
      calls = [
        ...calls,
        {
          abi: DAO_ABI,
          address: DAO,
          functionName: "totalBondedAt",
          args: [i],
        },
        {
          abi: DAO_ABI,
          address: DAO,
          functionName: "drawExecuted",
          args: [i],
        },
        {
          abi: DAO_ABI,
          address: DAO,
          functionName: "winnersAt",
          args: [i],
        },
        {
          abi: DAO_ABI,
          address: DAO,
          functionName: "winningTickets",
          args: [i],
        },
        {
          abi: DAO_ABI,
          address: DAO,
          functionName: "prizePerTicket",
          args: [i],
        },
        {
          abi: DAO_ABI,
          address: DAO,
          functionName: "userTicketRange",
          args: [address, i],
        },
        {
          abi: DAO_ABI,
          address: DAO,
          functionName: "userPrizeClaimed",
          args: [i, address],
        },
        {
          abi: TICKETS_ABI,
          address: TICKETS,
          functionName: "balanceOf",
          args: [address, i],
        },
      ];
    });

  // Fetch Data
  const { data: multicall } = useContractReads({
    enabled: address !== undefined,
    contracts: calls,
  }) as { data: { result: any }[] };

  let rounds: Round[] = [];

  Array(Number(data.epoch))
    .fill(0)
    .map((_, i) => {
      rounds.push({
        epoch: i,
        totalBondedAt: multicall?.[i * 8].result as bigint,
        drawExecuted: multicall?.[i * 8 + 1].result as boolean,
        winnersAt: multicall?.[i * 8 + 2].result as bigint,
        winningTickets: multicall?.[i * 8 + 3].result as bigint[],
        prizePerTicket: multicall?.[i * 8 + 4].result as bigint,
        userTicketRange: multicall?.[i * 8 + 5].result as bigint[],
        userPrizeClaimed: multicall?.[i * 8 + 6].result as bigint,
        tickets: multicall?.[i * 8 + 7].result as bigint,
      });
      return null;
    });

  const { config } = usePrepareContractWrite({
    address: DAO,
    abi: DAO_ABI,
    functionName: "mintTickets",
  });
  const { write: writeMint } = useContractWrite(config);
  return (
    <Layout>
      <Section className="max-w-5xl" grad>
        <div className="flex justify-between">
          <div className="flex gap-3 items-baseline">
            <h1 className="text-3xl font-bold">Lottery</h1>
            {/* <p className="text-primary">{`(${formatNum(userTickets)})`}</p> */}
          </div>
          {/* <div className="h-18">
            <input
              className="bg-foreground px-4 py-2 rounded-2xl focus-within:outline-none focus-within:ring-2 focus-within:ring-primary"
              placeholder="Search"
            />
          </div> */}
        </div>

        <ContextCard />

        {/* Leaderboard */}
        <Card className="min-h-[150px]">
          <h3 className="text-2xl font-bold">Tickets</h3>

          <div className="my-3 rounded-xl bg-primary/20 p-4">
            <div className="flex justify-between">
              {" "}
              <h3 className="text-xl pb-1 font-bold">
                Lottery Draw #{Number(data.epoch) + 1}
              </h3>
              {balances.bonded === BigInt(0) ? (
                <NavLink to="/overview">
                  <button className="bg-primary text-black px-4 py-2 rounded-lg flex items-center justify-center w-fit">
                    Bond your $888
                  </button>
                </NavLink>
              ) : epoch.balanceOf === BigInt(0) ? (
                <SimpleButton
                  color="bg-primary"
                  className="text-black"
                  onClick={() => writeMint && writeMint()}
                >
                  Mint Tickets
                </SimpleButton>
              ) : (
                <SimpleButton
                  color="bg-foreground"
                  className="text-gray-300"
                  disabled
                >
                  Tickets Minted!
                </SimpleButton>
              )}
            </div>

            <div className="text-left flex mb-6">
              <div className="text-xs text-gray-300 pr-6">
                Epoch:{" "}
                <span className="text-primary">{Number(data.epoch)}</span>
              </div>
              <div className="text-xs text-gray-300">
                Winning Tickets: <span className="text-primary">{0}</span>
              </div>
            </div>
            <div className={`flex flex-row justify-between`}>
              <div>
                <p className="text-sm text-slate-200">{"MINTED TICKETS"}</p>
                <p className="text-lg text-primary">
                  {`${formatNum(epoch.balanceOf)}`}
                </p>
              </div>
              <div>
                <p className="text-sm text-slate-200">{"ROUND PRIZE POT"}</p>
                <p className="text-lg text-primary">
                  {formatUSD(
                    Number(
                      formatUnits(
                        (data.thisEpochsPrizePool as bigint) || BigInt(0),
                        6
                      )
                    )
                  )}
                </p>
              </div>
              <div className="min-w-[200px]">
                <p className="text-sm text-slate-200">{"PRIZE PER TICKET"}</p>
                <p className="text-lg  text-primary">
                  {`1 = ${formatUSD(
                    Number(
                      formatUnits(
                        (data.thisEpochsPrizePool as bigint) || BigInt(0),
                        6
                      )
                    ) / 3
                  )}`}
                </p>
              </div>
            </div>
          </div>

          <Accordion type="single" collapsible className="w-full">
            {rounds.reverse().map((data, i) => (
              <RoundAccordian key={"round" + i} round={data} id={i} />
            ))}
          </Accordion>
        </Card>
      </Section>
    </Layout>
  );
}

const RoundAccordian = ({ round, id }: { round: Round; id: number }) => {
  // Check if a winner
  let winningTickets = 0;
  if (round.drawExecuted && round.winningTickets && round.userTicketRange) {
    round.winningTickets.map((n) => {
      if (round.userTicketRange[1] >= n && n >= round.userTicketRange[0])
        winningTickets++;
      return null;
    });
  }

  // Write REGISTER CODE
  const { config: configClaim, error: errorClaim } = usePrepareContractWrite({
    address: DAO,
    abi: DAO_ABI,
    functionName: "claimPrize",
    args: [BigInt(round.epoch)],
  });
  const { write: writeClaim } = useContractWrite(configClaim);

  const ticketsMinted =
    round && round.userTicketRange
      ? formatNum(
          Number(round.userTicketRange[1]) - Number(round.userTicketRange[0])
        )
      : 0;
  const bonded = formatNum(
    Number(formatUnits(round.totalBondedAt || BigInt(0), 18)),
    0
  );

  return (
    <AccordionItem value={id.toString()} className="px-2 border-b-[#363636]">
      <AccordionTrigger className="hover:no-underline py-4">
        <div className="text-left w-full pr-6">
          <h3 className="text-xl pb-1 font-bold">
            Lottery Draw #{round.epoch + 1}
          </h3>

          <div className="grid grid-flow-col gap-4">
            <div className="text-xs text-gray-300">
              Epoch: <span className="text-primary">{round.epoch}</span>
            </div>
            <div className="text-xs text-gray-300">
              Your Winning Tickets:{" "}
              <span className="text-primary">
                {round.drawExecuted ? winningTickets : "Pending"}
              </span>
            </div>
            <div className="text-xs text-gray-300">
              Winning Tickets:{" "}
              <span className="text-primary">
                {`${
                  round.drawExecuted
                    ? round.winningTickets.map((i) => ` ${i}`)
                    : "Pending"
                }`}
              </span>
            </div>
            <div className="text-xs text-gray-300">
              Closed{" "}
              {formatDistance(
                (CURRENT_EPOCH_START + CURRENT_EPOCH_PERIOD * round.epoch) *
                  1000,
                Date.now()
              )}{" "}
              ago
            </div>
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <div
          className={`grid ${
            Number(winningTickets) > 0 ? "grid-cols-5" : "grid-cols-4"
          } gap-6`}
        >
          <div>
            <p className="text-md text-slate-400">{"MINTED"}</p>
            <p className="text-lg  text-primary">
              {`${ticketsMinted}/${bonded}`}
            </p>
          </div>
          <div>
            <p className="text-md text-slate-400">{"ROUND PRIZE POT"}</p>
            <p className="text-lg  text-primary">
              {formatNum(
                Number(formatUnits(round.prizePerTicket || BigInt(0), 6)) * 3,
                2
              )}
            </p>
          </div>
          <div>
            <p className="text-md text-slate-400">{"PRIZE PER TICKET"}</p>
            <p className="text-lg  text-primary">
              {`1 = $${formatNum(
                Number(formatUnits(round.prizePerTicket || BigInt(0), 6))
              )}`}
            </p>
          </div>
          {
            <div>
              <p className="text-md text-slate-400">{"CLAIMABLE PRIZE"}</p>
              <p className="text-lg text-primary">
                {round.drawExecuted
                  ? winningTickets > 0
                    ? round.userPrizeClaimed
                      ? "Claimed"
                      : `$${formatNum(
                          Number(
                            formatUnits(round.prizePerTicket || BigInt(0), 6)
                          ) * winningTickets
                        )}`
                    : "No Winning Tickets"
                  : "Draw Pending"}
              </p>
            </div>
          }

          {winningTickets > 0 && round.drawExecuted ? (
            <SimpleButton
              color={"bg-primary"}
              className="text-black"
              onClick={() => writeClaim && writeClaim()}
            >
              Claim Your Prize
            </SimpleButton>
          ) : null}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};
